import React from 'react'
import { FirebaseAppProvider } from 'reactfire';
import config from './config'

const Provider = ({ children }) => (
  <FirebaseAppProvider firebaseConfig={config}>
    {children}
  </FirebaseAppProvider>
)

export default Provider
