import React, { forwardRef } from 'react';
import { Button as ChButton, IconButton } from "@chakra-ui/react";

import Link from './Link'

const Button = forwardRef(({ href, to, ...props }, ref) => {
  if (href || to) return <ChButton ref={ref} as={p => <Link {...p} href={href} to={to} ref={ref} />} {...props} />
  return <ChButton ref={ref} {...props} />
})

Button.defaultProps = {
  colorScheme: 'yellow',
  rounded: 'full',
  letterSpacing: '0.125em',
  border: '0.125em solid black',
  px: '1em',
  height: 'auto',
  pt: '0.25em',
  pb: '0.375em',
}

Button.Secondary = props => (
  <Button colorScheme="pink" {...props} />
)

Button.Pink = forwardRef((props, ref) => (
  <Button
    colorScheme="pink"
    bg="pink.300"
    color="black"
    _hover={{
      bg: 'pink.400',
    }}
    ref={ref}
    {...props}
  />
))

Button.Yellow = forwardRef((props, ref) => (
  <Button
    colorScheme="yellow"
    color="black"
    _hover={{
      bg: 'yellow.400',
    }}
    ref={ref}
    {...props}
  />
))

Button.Orange = forwardRef((props, ref) => (
  <Button
    colorScheme="orange"
    color="black"
    _hover={{
      bg: 'orange.400',
    }}
    _active={{
      bg: 'orange.400',
    }}
    ref={ref}
    {...props}
  />
))

Button.Giloo = forwardRef((props, ref) => (
  <Button
    variant="outline"
    borderWidth="4px"
    color="white"
    bg="giloo.purple"
    rounded="1.5em"
    borderColor="giloo.green"
    _hover={{
      color: 'white',
      bg: 'giloo.purple',
    }}
    _active={{
      color: 'giloo.green',
    }}
    ref={ref}
    {...props}
  />
))

Button.Danger = props => (
  <Button colorScheme="red" {...props} />
)

Button.Icon = forwardRef(({ href, to, ...props }, ref) => {
  if (href || to) return <IconButton ref={ref} as={p => <Link {...p} href={href} to={to} ref={ref} />} {...props} />
  return <IconButton ref={ref} {...props} />
})

export default Button
