import React from 'react'

import ThemeProvider from './src/components/ThemeProvider'

const Providers = ({ element }) =>
  <ThemeProvider>
    {element}
  </ThemeProvider>

export default Providers
