import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore/lite';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import React, { useEffect } from 'react'
import { FirestoreProvider, FunctionsProvider, useFirebaseApp } from 'reactfire';

const FirebaseSDK = ({ children }) => {
  const app = useFirebaseApp()
  const db = getFirestore(app)
  const functions = getFunctions(app)
  useEffect(() => {
    if (typeof window !== "undefined" && process.env.GATSBY_FIREBASE_EMULATOR === 'true') {
      connectFirestoreEmulator(db, 'localhost', 8080);
      connectFunctionsEmulator(functions, "localhost", 5001);
    }
  }, [app])
  return (
    <FirestoreProvider sdk={db}>
      <FunctionsProvider sdk={functions}>
        {children}
      </FunctionsProvider>
    </FirestoreProvider>
  )
}

export default FirebaseSDK
