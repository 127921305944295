exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bonne-chance-index-js": () => import("./../../../src/pages/bonne-chance/index.js" /* webpackChunkName: "component---src-pages-bonne-chance-index-js" */),
  "component---src-pages-bonne-chance-play-js": () => import("./../../../src/pages/bonne-chance/play.js" /* webpackChunkName: "component---src-pages-bonne-chance-play-js" */),
  "component---src-pages-bonne-chance-result-js": () => import("./../../../src/pages/bonne-chance/result.js" /* webpackChunkName: "component---src-pages-bonne-chance-result-js" */),
  "component---src-pages-giloo-index-js": () => import("./../../../src/pages/giloo/index.js" /* webpackChunkName: "component---src-pages-giloo-index-js" */),
  "component---src-pages-giloo-play-js": () => import("./../../../src/pages/giloo/play.js" /* webpackChunkName: "component---src-pages-giloo-play-js" */),
  "component---src-pages-giloo-result-js": () => import("./../../../src/pages/giloo/result.js" /* webpackChunkName: "component---src-pages-giloo-result-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-justfont-index-js": () => import("./../../../src/pages/justfont/index.js" /* webpackChunkName: "component---src-pages-justfont-index-js" */),
  "component---src-pages-justfont-play-js": () => import("./../../../src/pages/justfont/play.js" /* webpackChunkName: "component---src-pages-justfont-play-js" */),
  "component---src-pages-justfont-result-js": () => import("./../../../src/pages/justfont/result.js" /* webpackChunkName: "component---src-pages-justfont-result-js" */),
  "component---src-pages-plainlaw-index-js": () => import("./../../../src/pages/plainlaw/index.js" /* webpackChunkName: "component---src-pages-plainlaw-index-js" */),
  "component---src-pages-plainlaw-play-js": () => import("./../../../src/pages/plainlaw/play.js" /* webpackChunkName: "component---src-pages-plainlaw-play-js" */),
  "component---src-pages-plainlaw-result-js": () => import("./../../../src/pages/plainlaw/result.js" /* webpackChunkName: "component---src-pages-plainlaw-result-js" */)
}

