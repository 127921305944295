import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import ErrorBoundary from './ErrorBoundary'

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              siteUrl
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            defaultTitle={data.site.siteMetadata.title}
            titleTemplate={`%s｜${data.site.siteMetadata.title}`}
          >
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="愛研究溝通的資訊設計團隊 Re-lab 十歲了！今年我們開發了一款測驗工具，以此和六個好朋友換到了許多酷東西！"
            />
          </Helmet>
          <ErrorBoundary location={location}>
            {children}
          </ErrorBoundary>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
