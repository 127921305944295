import React from 'react'
import {
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Container,
} from '@chakra-ui/react'

import Button from '../components/Button';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const homeReg = /^\/([^/]+)/.exec(this.props.location?.pathname)
      return (
        <Container>
          <Stack py="4" spacing="4">
            <Text fontSize="md">哎呀，遇到錯誤了！</Text>
            {this.state.hasError.message && (
              <Accordion>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex='1' textAlign='left'>
                      顯示錯誤訊息
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} color="red.500">
                    {this.state.hasError.message}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
            <Box>
              <Button href={homeReg ? `/${homeReg[1]}/` : '/'}>重新返回測驗</Button>
            </Box>
          </Stack>
        </Container>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary
