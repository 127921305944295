import pickBy from 'lodash/pickBy'
import { useMemo } from 'react'
import { useSearchParam, useSessionStorage } from 'react-use'

export const REF_KEY = 'supersurvey:ref'

const UtmRecorder = () => {
  const ref = useSearchParam('ref')
  const utm = useMemo(() => {
    const utmParams = {}
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      params.forEach((value, key) => {
        const res = /^utm_(\w+)$/.exec(key)
        if (res) {
          utmParams[res[1]] = value
        }
      })
    }
    return utmParams
  }, [])
  useSessionStorage(REF_KEY, pickBy({
    ref,
    ...utm,
  }, Boolean))
  return null
}

export default UtmRecorder
