const firebaseConfig = {
  apiKey: "AIzaSyDh_53O3cGuBqZUN3KqifMR7FqnpXk5j_Q",
  authDomain: "super-survey-fcb50.firebaseapp.com",
  databaseURL: "https://super-survey-fcb50-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "super-survey-fcb50",
  storageBucket: "super-survey-fcb50.appspot.com",
  messagingSenderId: "563123227032",
  appId: "1:563123227032:web:868f3d55fad7388c0a03ed"
};

export default firebaseConfig
