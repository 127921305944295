/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import 'focus-visible'

// import './webp'
import wrapWithProvider from './with-provider';
import Layout from './src/containers/Layout'
import UtmRecorder from './src/containers/UtmRecorder';
import FirebaseProvider from './src/firebase/provider'
import FirebaseSDK from './src/firebase/sdk'
// import webfontLink from './webfont-link'

export const wrapRootElement = ({ element }) => (
  <FirebaseProvider>
    <FirebaseSDK>
      {wrapWithProvider({ element })}
    </FirebaseSDK>
  </FirebaseProvider>
);

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <Layout {...props}>
      {element}
      <UtmRecorder />
    </Layout>
  )
}

// export const onInitialClientRender = () => {
//   const link = document.createElement('link')
//   link.href = webfontLink
//   link.rel = 'stylesheet'
//   document.head.append(link)
// }

if (process.env.NODE_ENV === 'development') {
  const { getLCP, getFID, getCLS } = require('web-vitals');
  getCLS(console.log);
  getFID(console.log);
  getLCP(console.log);
  window.gtag = console.log
}
